import { Button } from '@chakra-ui/react';
import { Link } from 'gatsby';
import * as React from 'react';
import { CustomButtonProps } from '../typings';

const SecondaryButton = ({
  children,
  type = 'button',
  link,
  bgColor = '#283447',
  maxW = '450px',
  paddingY = '25px',
  paddingX = '40px',
  borderRadius = '55px',
  alignContent = 'center',
  marginX,
  ...rest
}: CustomButtonProps): JSX.Element => {
  return (
    <Link style={{ maxWidth: '300px' }} to={link || '/'}>
      <Button
        fontFamily="Circular Std Book"
        _hover={{ bg: '#e8eaeb', color: '#384955' }}
        paddingY={paddingY}
        bgColor={bgColor}
        color="#fff"
        maxW={maxW}
        paddingX={paddingX}
        borderRadius={borderRadius}
        alignContent={alignContent}
        type={type}
        marginX={marginX}
        {...rest}
      >
        {children}
      </Button>
    </Link>
  );
};

export default SecondaryButton;
