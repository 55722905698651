import * as React from 'react';
import { Button } from '@chakra-ui/react';
import { CustomButtonProps } from '../typings';

const PrimaryButton = ({
  children,
  type = 'button',
  bgColor = '#4fc1ff',
  paddingY,
  paddingX,
  borderRadius = '25px',
  alignContent = 'center',
  marginX = '10px',
  ...rest
}: CustomButtonProps): JSX.Element => {
  return (
    <Button
      fontFamily="Circular Std Medium"
      _hover={{ bg: '#000', color: '#fff' }}
      paddingY={paddingY}
      bgColor={bgColor}
      color="#fff"
      // maxW={maxW}
      paddingX={paddingX}
      borderRadius={borderRadius}
      alignContent={alignContent}
      type={type}
      marginX={marginX}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
