import * as React from 'react';
import { Button, SystemCSSProperties, Link } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
// import { Link } from 'gatsby';

interface ButtonProps {
  children: string;
  type: 'button' | 'submit' | 'reset';
  direction?: 'forward' | 'back';
  onClick?: () => void;
  disabled?: boolean;
  style?: SystemCSSProperties;
  to?: string;
}

const SecondaryButtonF = ({
  children,
  type = 'button',
  onClick,
  style,
  to,
}: ButtonProps): JSX.Element => {
  return (
    <Link style={{ maxWidth: '300px' }} href={to}>
      <Button
        type={type}
        onClick={onClick}
        minW={['100%', '300px']}
        rightIcon={<ArrowForwardIcon />}
        colorScheme="teal"
        variant="outline"
        {...style}
      >
        {children}
      </Button>
    </Link>
  );
};

export default SecondaryButtonF;
