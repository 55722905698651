import * as React from 'react';
import { Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

interface ButtonProps {
  children: string;
  type: 'button' | 'submit' | 'reset';
  direction?: 'forward' | 'back';
  onClick: () => void;
}

const SecondaryButtonB = ({
  children,
  type = 'button',
  onClick,
  ...rest
}: ButtonProps): JSX.Element => {
  return (
    <Button
      type={type}
      maxWidth={['100%', '250px']}
      leftIcon={<ArrowBackIcon />}
      // rightIcon={<ArrowForwardIcon />}
      colorScheme="teal"
      variant="outline"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SecondaryButtonB;
